<template>
  <div class="page">
    <!-- <div class="top_box">
      <img
        @click="toPath('classScheduleCard')"
        src="./img/courseListBanner.png"
        alt=""
      />
    </div> -->
    <!-- 在线学习tab -->
    <div class="tab_box" @click="toPath('onlineStudy')">
      <div class="title">在线学习</div>
      <div class="more">更多</div>
      <img class="more_icon" src="./img/arrow1.png" />
    </div>
    <!-- 在线学习列表 -->
    <div class="online_learning_list">
      <div
        class="item"
        @click="toDetail(item)"
        v-for="(item, index) in study_list"
        :key="index"
      >
        <div class="img_box">
          <div class="bg_box">
            <img class="bg" :src="item.topicPicture" alt="" />
          </div>
          <div class="box">
            <div class="quantity">{{ item.readCount }}次播放</div>
            <div class="duration">
              <div class="imgs">
                <img class="duration_icon" src="./img/good.png" alt="" />
              </div>
              <div class="infos">{{ item.praiseCount }}</div>
            </div>
          </div>
        </div>
        <div class="txt_box">
          {{ item.topicTitle }}
        </div>
      </div>
    </div>
    <!-- 师资库tab -->
    <div class="tab_box" @click="toPath('teacherLibrary')">
      <div class="title">师资库</div>
      <div class="more">更多</div>
      <img class="more_icon" src="./img/arrow1.png" />
    </div>
    <!-- 师资库列表 -->
    <div class="teacher_list">
      <!-- <div class="box"> -->
      <div
        class="item"
        @click="toPath('teacherDetail', item)"
        v-for="(item, index) in teacher_list"
        :key="index"
      >
        <div class="top">
          <div class="infos">{{ item.name }}</div>
          <div class="imgs">
            <div v-if="item.sex == 2">
              <img class="icon" src="./img/female_icon.png" alt="" />
            </div>
            <div v-else>
              <img class="icon" src="./img/male_icon.png" alt="" />
            </div>
          </div>
        </div>
        <div class="bottom">
          <p v-html="item.introduction"></p>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
import { getTeacherList, getTopicListUrl } from "./api.js";
import wx from "weixin-js-sdk";
export default {
  name: "grassRootsPartySchool",
  data() {
    return {
      fromFlag: "",
      study_list: [],
      requestData: {
        curPage: 1,
        pageSize: 4,
      },
      teacher_list: [],
      requestData1: {
        curPage: 1,
        pageSize: 3,
      },
      status: "1",
    };
  },
  computed: {
    communityId() {
      return this.$store.state.communityId;
    },
    tenantId() {
      return this.$store.state.tenantId;
    },
    userId() {
      return this.$store.state.userId;
    },
  },
  created() {
    this.fromFlag = this.$route.query.fromFlag;
    this.getStudyList();
    this.geiTeacherList();
  },
  methods: {
    geiTeacherList() {
      //获取师资库列表
      let params = {
        name: "",
        // communityId: this.communityId,
        curPage: this.requestData1.curPage,
        pageSize: this.requestData1.pageSize,
        // tenantId: this.tenantId,
        status: this.status,
      };
      this.$axios
        .get(`${getTeacherList}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            this.teacher_list = res.data.records;
          } else {
          }
        });
    },
    getStudyList() {
      //获取在线学习课程列表
      let params = {
        name: "",
        // communityId: this.communityId,
        curPage: this.requestData.curPage,
        pageSize: this.requestData.pageSize,
        // tenantId: this.tenantId,
        status: this.status,
        topicType: "18",
      };
      this.$axios
        .get(`${getTopicListUrl}`, {
          params: params,
        })
        .then((res) => {
          if (res.code === 200) {
            console.log(res);
            this.study_list = res.data.records;
          } else {
          }
        });
    },
    toPath(path, data) {
      this.$router.push({
        name: path,
        query: data
          ? { fromFlag: this.fromFlag, ...data }
          : { fromFlag: this.fromFlag },
      });
    },
    // 在线学习跳转至小程序页面
    toDetail(item) {
      let params = {
        id: item.id,
      };
      if (this.fromFlag == "dingding") {
        this.$router.push({
          name: "onLineStudyDetail",
          query: {
            id: item.id,
          },
        });
      } else {
        if (wx.miniProgram) {
          wx.miniProgram.navigateTo({
            url: `/xubPages/onLineStudyDetail/index?params=${JSON.stringify(
              params
            )}`,
            // url: `/xubPages/onLineStudyDetail/index?`,
          });
        }
      }
    },
  },
};
</script>

<style lang="less">
.page {
  width: 100%;
  height: 100vh;
  background-color: #fff;

  .teacher_list::-webkit-scrollbar {
    display: none;
  }

  .teacher_list {
    width: 100%;
    height: 300px;
    display: flex;
    flex-wrap: nowrap;
    overflow-y: hidden;
    overflow-x: scroll;

    .item {
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      border-radius: 17px;
      display: inline-block;
      width: 304px;
      height: 234px;
      background-color: #fff;
      margin-left: 30px;
      box-sizing: border-box;

      .bottom {
        box-sizing: border-box;
        white-space: normal;
        width: 304px;
        height: 126px;
        line-height: 45px;
        padding: 0 15px 18px;
        font-size: 30px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.5);
        overflow: hidden;
        text-overflow: ellipsis; //显示省略号
        display: -webkit-box;
        -webkit-line-clamp: 3; //块元素显示的文本行数
        -webkit-box-orient: vertical;
      }

      .top {
        width: 304px;
        height: 90px;
        line-height: 90px;
        padding-left: 16px;
        box-sizing: border-box;

        .imgs {
          display: inline-block;
          vertical-align: middle;
          margin-right: 6px;

          .icon {
            width: 30px;
            height: 30px;
          }
        }

        .infos {
          display: inline-block;
          font-size: 34.61px;
          font-weight: 600;
          color: #1a1c34;
        }
      }
    }
  }

  .online_learning_list {
    display: flex;
    flex-wrap: wrap;
    padding: 0px 32px 0;
    justify-content: space-between;

    .item {
      box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
      // box-sizing: border-box;
      margin-top: 16px;
      border-radius: 12px;
      overflow: hidden;

      &:nth-of-type(1),
      &:nth-of-type(2) {
        padding-top: 0;
        margin-top: 0;
      }

      .txt_box {
        box-sizing: border-box;
        width: 333px;
        height: 102px;
        line-height: 45px;
        background-color: #fff;
        box-shadow: 0px 0px 32px 0px rgba(66, 114, 153, 0.1);
        padding: 12px;
        font-size: 28px;
        color: #323334;
        font-weight: 400;
        overflow: hidden;
        text-overflow: ellipsis; //显示省略号
        display: -webkit-box;
        -webkit-line-clamp: 2; //块元素显示的文本行数
        -webkit-box-orient: vertical;
      }

      .img_box {
        width: 333px;
        height: 214px;
        position: relative;

        .box {
          width: 100%;
          height: 50px;
          line-height: 50px;
          position: absolute;
          bottom: 0;
          left: 0;

          .duration {
            font-size: 22px;
            color: #fff;
            position: absolute;
            top: 50%;
            right: 12px;
            transform: translateY(-50%);

            .imgs {
              display: inline-block;
              vertical-align: middle;
              margin-right: 6px;

              .duration_icon {
                width: 24px;
                height: 24px;
              }
            }

            .infos {
              display: inline-block;
            }
          }

          .quantity {
            font-size: 20px;
            color: #fff;
            position: absolute;
            top: 50%;
            left: 14px;
            transform: translateY(-50%);
          }
        }

        .bg_box {
          overflow: hidden;
          width: 333px;
          height: 214px;
          border-radius: 12px 12px 0px 0px;
          .bg {
            width: 100%;
            border-radius: 12px 12px 0px 0px;
            height: 100%;
          }
        }
      }
    }
  }

  .tab_box {
    width: 686px;
    height: 94px;
    margin: 0 auto;
    position: relative;

    .title {
      font-size: 32px;
      color: #262626;
      font-weight: 600;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }

    .more {
      font-size: 28px;
      color: #000;
      font-weight: 600;
      position: absolute;
      top: 50%;
      right: 40px;
      transform: translateY(-50%);
    }

    .more_icon {
      width: 40px;
      height: 40px;
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }

  .top_box {
    width: 100%;
    height: 212px;
    position: relative;

    img {
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 690px;
      height: 192px;
    }
  }
}
</style>
