// let origin = "https://wantou.zhuneng.cn";
//积分
const pointsUrl = `/gateway/hc-accumulated/pointuser/getPointUserDetail`;
//党务通
const getTopicListURL = `/gateway/hc-neighbour/topic/getTopicList`;
//民主评议列表
const rightsCommentListUrl = `/gateway/hc-neighbour/cpAssessment/getCpAssessmentList`;
//师资库列表
const getTeacherList = `/gateway/hc-govern/cpbTeacher/getCpbTeacherList`;
//获取师资详情
const getCpbTeacherInfoByIdUrl = `/gateway/hc-govern/cpbTeacher/getCpbTeacherInfoById`;
//获取课程列表前获取页码
const getInitPageNoUrl = `/gateway/hc-govern/cpbCourse/getInitPageNo`;
//党建课程列表查询
const getCpbCourseListUrl = `/gateway/hc-govern/cpbCourse/getCpbCourseList`;
// 话题列表
const getTopicListUrl = `/gateway/hc-neighbour/topic/getTopicList`;
// 话题详情
const getTopicInfoByIdUrl = `/gateway/hc-neighbour/topic/getTopicInfoById`;
// 在线学习详情
const getTopicInfoByIdURL = `gateway/hc-neighbour/topic/getTopicInfoById`;
//增加阅读数
const getCountUrl = `/gateway/hc-neighbour/topic/addReadCount`;
// 在线学习获得积分
const doCpbELearningUrl = `/gateway/hc-accumulated/dailyPerf/doCpbELearning`;
// 点赞
const addPraiseURL = `${origin}/gateway/hc-neighbour/topic/addPraise`;
// 取消点赞
const cancelPraiseURL = `${origin}/gateway/hc-neighbour/topic/cancelPraise`;
// 进行评论
const addCommentURL = `${origin}/gateway/hc-neighbour/topic/addComment`;
// 话题评论列表分页查询
const getTopicCommentList = `${origin}/gateway/hc-neighbour/topic/getTopicCommentList`;
// 评论回复列表分页查询
const getTopicReplyList = `${origin}/gateway/hc-neighbour/topic/getTopicReplyList`;
export {
  getCpbCourseListUrl,
  pointsUrl,
  getTopicListURL,
  rightsCommentListUrl,
  getTeacherList,
  getCpbTeacherInfoByIdUrl,
  getInitPageNoUrl,
  getTopicListUrl,
  getTopicInfoByIdUrl,
  getTopicInfoByIdURL,
  getCountUrl,
  // ------
  doCpbELearningUrl,
  addPraiseURL,
  cancelPraiseURL,
  addCommentURL,
  getTopicCommentList,
  getTopicReplyList,
};
